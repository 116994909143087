/* eslint-disable */
import {Pagination, Autoplay, Navigation} from 'swiper/modules';
import {Swiper} from 'swiper'
import 'swiper/css';

Swiper.use([Pagination, Autoplay, Navigation])

window.addEventListener('load', () => {
  heroSliderBlock.init();
});

const heroSliderBlock = {
  init: function () {
    const sections = document.querySelectorAll('[data-hero-slider-block-js]');

    if (!sections.length) return;

    sections.forEach((section) => {
      this.initSection(section)
    })

  },

  initSection: function (section) {
    let sliderEl = section.querySelector('[data-hero-slider-js]');
    if (!sliderEl) return;

    this.initSwiper(sliderEl, section);
  },

  initSwiper: function (sliderEl, section) {
    const buttonNext = section.querySelector('.swiper-button-next');
    const buttonPrev = section.querySelector('.swiper-button-prev');
    const pagination = section.querySelector('.swiper-pagination');

    let swiper = new Swiper(sliderEl, {
      speed: 1500,
      observer: true,
      observeParents: true,
      grabCursor: true,
      slidesPerView: 1,
      // loop: true,
      navigation: {
        nextEl: buttonNext,
        prevEl: buttonPrev,
      },
      pagination: {
        el: pagination,
        type: 'bullets',
      },
      on: {
        slideChange: function () {
          let activeIndex = this.snapIndex;
          console.log(activeIndex);
          heroSliderBlock.initNumbersBlock(section, activeIndex);
        }
      }
    });
  },

  initNumbersBlock: function (section, index) {
    let items = section.querySelectorAll('.hero-slider__numbers-area-inner');
    if (!items || items.length === 0) return;

    items.forEach((item) => {
      item.classList.remove('is-active');
    });

    if (items[index]) {
      items[index].classList.add('is-active');
    }
  }
}

export default heroSliderBlock;
